import React from "react"
import Seo from "../shared/seo/seo"
import LayoutPortfolio from "../shared/layout-portfolio/layout-portfolio"
import ImgDetail from "../shared/img-detail/img-detail"
import { graphql, useStaticQuery } from "gatsby"

const Houses = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          projects {
            private_house {
              title
            }
          }
        }
      }
    }
  `)
  return (
    <LayoutPortfolio>
      <Seo title={data.site.siteMetadata.projects.private_house.title}></Seo>
      <div className="container">
        <div className="row ">
          <div className="col-xl-6 col-lg-6 col-md-12 mt-4 ">
            <ImgDetail
              folder="houses"
              path="istambul41628.png"
              description="INGRESSO INTERAMENTE RIVESTITO IN NOCE CANALETTO"
            ></ImgDetail>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 mt-4 ">
            <ImgDetail
              folder="houses"
              path="istambul41623.png"
              description="PARETE IN NOCE CANALETTO CON DETTAGLI IN PELLE E CARTA DI RISO, ILLUMINAZIONE INTEGRATA"
            ></ImgDetail>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <ImgDetail
              folder="houses"
              path="rsz_img_0177.png"
              description="CUCINA RIVESTITA IN ACCIAIO CON PIANI IN CORIAN"
            ></ImgDetail>
          </div>
        </div>
        <div className="row mt-4 ">
          <div className="col-xl-6 col-lg-6 col-md-12 mt-4">
            <ImgDetail
              folder="houses"
              path="fb_1244.png"
              ph="Federico Buscarino"
              description="PORTA A DOPPIA ANTA IN ROVERE DI RECUPERO DA VECCHI TINI"
            ></ImgDetail>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 mt-4">
            <ImgDetail
              folder="houses"
              path="fb_0918.png"
              ph="Federico Buscarino"
              description="GUARDAROBA LACCATO RIVESTITO INTERNAMENTE IN SETA"
            ></ImgDetail>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <ImgDetail
              folder="houses"
              ph="Federico Buscarino"
              path="rsz__fb_0903.png"
              description="LETTO E COMODINI IN RADICA, PARETE RIVESTITA IN SETA"
            ></ImgDetail>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <ImgDetail
              folder="houses"
              path="rsz__dsc4373.png"
              ph="Federico Buscarino"
              description="LIBRERIA SU DISEGNO LACCATA"
            ></ImgDetail>
          </div>
        </div>
      </div>
    </LayoutPortfolio>
  )
}

export default Houses
